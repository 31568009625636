import React from "react";
import fal from "./fal.jpg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={fal} className="App-logo" alt="logo" class="center" />
        <p>
          <i>Join our congregation!</i>
        </p>
      </header>
      <footer className="App-footer">
        <p>Copyright © 2024</p>
      </footer>
    </div>
  );
}

export default App;
